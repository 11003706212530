import * as React from "react";
import {
  ChakraProvider
} from "@chakra-ui/react";
import theme from "./util/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home";
import { FreelancePage } from "./pages/freelance";

export default class App extends React.Component {
  componentDidMount(): void {
    window.localStorage.setItem("chakra-ui-color-mode", "dark");
  }

  render() {
    return (
      <ChakraProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route index path="/" element={<HomePage />} />
              <Route path="/freelance" element={<FreelancePage />} />
            </Routes>
          </BrowserRouter>
      </ChakraProvider>
    )
  }
}