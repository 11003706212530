import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    fonts: {
        body: `'Chivo Mono', sans-serif`,
        heading: `'Chivo Mono', sans-serif`
    },
    initialColorMode: 'dark',
    useSystemColorMode: false,
});

export default theme;