import * as React from "react";
import {
  Box,
  Text,
  Heading,
  Center,
  Link,
  Badge,
  Stack,
  Code,
  HStack,
  Card, 
  CardBody,
  useToast,
  Tooltip
} from "@chakra-ui/react";
import { FaDiscord, FaSnapchatGhost, FaTwitter } from "react-icons/fa";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

export const HomePage = () => {
  let toast = useToast();

  return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2 }}
      >
        <Box textAlign="left" fontSize="large">
          <Center p={3} marginTop={"15%"}>
              <Box textAlign={"left"}>
                <Stack spacing="30px">
                  <Box>
                    <Heading fontSize={"larger"}>
                      <Typewriter 
                        options={{
                          strings: ["Hi, I'm Kai."],
                          autoStart: true,
                          deleteSpeed: Infinity,
                          loop: false
                        }} />
                    </Heading>
                    <Stack direction='row'>
                      {["webdev", "fullstack", "thirteenyearsold", "selftaught"].map((t) => (<Badge key={t.toLowerCase()}>#{t.toLowerCase()}</Badge>))}
                    </Stack>
                    <Box marginTop={"15px"}>
                      <Stack>
                        <Text>
                          A thirteen year old developer from <b>Boston, MA</b>.
                        </Text>
                        <Text>
                          Currently interested in <Link href={"https://robinhood.com/crypto/ETH"} target={"_blank"} className={"hover-underline-animation"} style={{ textDecoration: 'none' }} fontWeight={"bold"}>$ETH</Link> smart contracts and <br /><Link href={"https://en.wikipedia.org/wiki/Machine_learning"} target={"_blank"} className={"hover-underline-animation"} style={{ textDecoration: 'none' }} fontWeight={"bold"}>machine learning</Link>.
                        </Text>
                      </Stack>
                    </Box>
                  </Box>
                  <Box>
                    <Stack>
                      <Code fontSize={"larger"}>
                        <span style={{"color": "#e6cd69"}}>
                          let
                        </span>
                        <span style={{"color": "#55b5db"}}>
                          {" previousWork "}
                        </span>
                        <span style={{"color": "#9fca56"}}>
                          {"= "}
                        </span>
                        {"["}
                        {[
                          {
                            "name": "Freelance",
                            "link": "/freelance",
                            "first": true
                          }
                        ].map((e) => (
                          <>
                            {e.first === true && (
                              <span style={{"color": "#55b5db"}}>
                                {"\""}
                              </span>
                            )}
                            {!(e.first) && (
                              <span style={{"color": "#55b5db"}}>
                                {", \""}
                              </span>
                            )}
                            <Link href={e.link} className={"hover-underline-animation"} style={{ textDecoration: 'none', color: "#55b5db" }} fontWeight={"bold"}>
                                {e.name}
                            </Link>
                            <span style={{"color": "#55b5db"}}>
                              {"\""}
                            </span>
                          </>
                        ))}
                        {"];"}
                      </Code>
                    </Stack>
                  </Box>
                  <Box>
                    <Stack>
                      <Heading fontSize={"larger"}>
                        {"Contact Me"}
                      </Heading>
                      <HStack spacing={"8"}>
                          {[
                            {
                              "name": "Discord",
                              "activeness": "Moderately active (3-4 times per week)",
                              "icon": FaDiscord,
                              "link": "Sync#6969"
                            },
                            {
                              "name": "Snapchat",
                              "activeness": "Extremely active (all the time)",
                              "icon": FaSnapchatGhost,
                              "link": "https://t.snapchat.com/w4pW8ELD"
                            }
                          ].map((e) => (
                            <Card width={"500px"} _hover={{
                              backgroundColor: "gray.600",
                              cursor: "pointer"
                            }} onClick={() => {
                              if (e.name.includes("Discord"))
                              {
                                navigator.clipboard.writeText(e.link);
                                toast({
                                  title: 'Copied my Discord tag to the clipboard',
                                  description: "",
                                  status: 'success',
                                  duration: 4000,
                                  isClosable: true,
                                });
                              } else {
                                window.open(e.link);
                              }
                            }} >
                              <CardBody>
                                <Heading fontSize={"md"}>
                                  <Text style={{"float": "left"}}>
                                    <span style={{"verticalAlign": "middle", "display": "inline-block"}}>
                                      {e.name}
                                    </span>
                                  </Text>
                                </Heading><br />
                                <Text color={"gray.400"}>
                                  <Typewriter
                                    options={{
                                      strings: [e.activeness],
                                      autoStart: true,
                                      loop: false,
                                      deleteSpeed: Infinity,
                                    }}
                                  />
                                </Text>
                              </CardBody>
                            </Card>
                          ))}
                      </HStack>
                      {/*
                      <Text color={"gray.300"} textAlign={"center"}>or</Text>
                      <HStack spacing={"8"}>
                      <Card width={"100%"} _hover={{
                              bgGradient: "linear(to-tr, gray.300, yellow.400)",
                              cursor: "pointer"
                            }}>
                              <CardBody>
                                <Heading fontSize={"md"}>
                                  <Text style={{"float": "left"}}>
                                    <span style={{"verticalAlign": "middle", "display": "inline-block"}}>
                                      Donate $BTC
                                    </span>
                                  </Text>
                                </Heading><br />
                                <Text color={"gray.400"}>
                                  <Typewriter
                                    options={{
                                      strings: ["Almost at goal of new speaker!"],
                                      autoStart: true,
                                      loop: false,
                                      deleteSpeed: Infinity,
                                    }}
                                  />
                                </Text>
                              </CardBody>
                            </Card>
                      </HStack>
                                  */}
                    </Stack>
                  </Box>
                </Stack>
              </Box>
          </Center>
        </Box>
      </motion.div>
  )
}