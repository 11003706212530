import * as React from "react";
import {
  Box,
  Text,
  Heading,
  Center,
  Link,
  Stack
} from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import { motion } from "framer-motion";

export const FreelancePage = () => {
  return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2 }}
      >
        <Center width={"100%"} height={"100%"} p={3} marginTop={"15%"} marginLeft={"50vh"}>
                <Box textAlign={"left"}>
                  <Stack spacing="30px">
                    <Box>
                      <Heading fontSize={"larger"}>
                        <Link href={"/"}><FaArrowLeft /></Link> Freelance
                      </Heading>
                      <Text fontSize={"lg"} color={"gray.300"}>
                        Full-stack developer
                      </Text>
                      <Text fontSize={"md"} color={"gray.300"}>
                        <b>2019</b>-<b>present</b>
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize={"large"} wordBreak={"break-word"} width={"40%"}>
                        Since <b>2019</b> (with parental supervision), I've been doing freelance work! I've worked on a mulititude of projects; many of which have ended up wildly successful.
                      </Text>
                    </Box>
                  </Stack>
                </Box>
        </Center>
      </motion.div>
  )
}